/* :root {
	--bs-primary: #f99403;
} */

body > .container {
	max-width: 900px;
}

.flex-center-wrap {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}

.warning-text {
	color: #a86200;
}

.bi-clipboard-check {
	margin-left: 0.5rem;
}

/* #copy-to-clipboard-button {
	background-color: var(--bs-success);
	color: white;
} */

#password_expire_after_days {
	height: auto;
	margin: 0 0.6rem;
}

.viewing-secret-container {
	border-top: solid;
	border-bottom: solid;
	border-color: var(--bs-gray-400);
	background-color: var(--bs-gray-200);
	color: var(--bs-gray-900);
}

textarea.form-control {
	font-family: monospace;
	white-space: pre;
}

@media (prefers-color-scheme: dark) {
	:root {
		--bs-body-bg: var(--bs-gray-800);
	}

	.warning-text {
		color: #dd971d;
	}

  .btn-outline-secondary {
    color: var(--bs-gray-400);
    border-color: var(--bs-gray-400);
  }

	.btn-success {
		background-color: hsl(135 80% 30%);
		border-color: hsl(135 80% 30%);
	}

	textarea.form-control,
	textarea.form-control:focus,
	input.form-control:not([type=submit]),
	input.form-control:focus:not([type=submit]) {
		color: var(--bs-gray-200);
		background-color: var(--bs-gray-700);
	}

	input[type=range]::-webkit-slider-runnable-track {
		background-color: var(--bs-gray-500);
	}
	
	.viewing-secret-container {
		border-color: var(--bs-gray-600);
		background-color: var(--bs-gray-700);
		color: var(--bs-gray-300);
	}
}
